import { initIntercom } from '$lib/utils/chat/intercom'
import { initMixpanel } from '$lib/utils/tracking/mixpanel'
import type { HandleClientError } from '@sveltejs/kit'
import * as Sentry from '@sentry/svelte'
import {
  PUBLIC_ENVIRONMENT,
  PUBLIC_PUBLIC_SENTRY_DSN,
} from '$env/static/public'
import { version } from '$app/environment'

initMixpanel()
initIntercom()

Sentry.init({
  dsn: PUBLIC_PUBLIC_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  environment: PUBLIC_ENVIRONMENT,
  enabled: window.location.hostname !== 'localhost',
  release: version,
  beforeSend(event) {
    // Ignore failed to fetch errors from sveltekit
    const sveltekitFailedtoFetchError = event.exception?.values?.some(
      (value) =>
        value.type === 'TypeError' &&
        value.value === 'Failed to fetch' &&
        value.stacktrace?.frames?.some((frame) =>
          frame.filename?.includes('@sveltejs/kit'),
        ),
    )

    if (sveltekitFailedtoFetchError) {
      return null
    } else {
      return event
    }
  },
})

Sentry.setTag('public', 'browser')

export const handleError: HandleClientError = ({ error, event }) => {
  Sentry.captureException(error, {
    contexts: { sveltekit: { event: JSON.stringify(event) } },
  })

  return {
    user_message: 'Something went wrong',
    message:
      error &&
      typeof error === 'object' &&
      'message' in error &&
      typeof error.message === 'string'
        ? error.message
        : 'Something went wrong',
    url: event.url.href,
  }
}
